import React, { useEffect } from 'react'
import PageHelmet from '../components/PageHelmet'
import notFound from '../images/not-found.png'

const NotFoundPage = () => {
  const styles = `
    .not-found-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Nunito Sans';
      padding: 11vh 0;
    }
    .not-found-container h2 {
      font-size: 36px;
      font-weight: 300;
      padding: 0;
      margin: 0;
      line-height: normal;
      max-width: 720px;
    }
    .not-found-container h1 {
      opacity: 0;
      position: absolute;
    }
    .not-found-image {
      display: inline-block;
      max-width: 743px;
      width: 100%;
      margin: 0 auto 46px;
    }
    .not-found-container .helping-links {
      font-size: 20px;
    }
    .not-found-container .helping-links a {
      color: black;
      padding: 5px 0;
      margin: 25px 15px;
      display: inline-block;
      border-bottom-color: black;
    }
    
    .not-found-container .helping-links a:hover {
      border-bottom-color: #E3650C;
      color: #E3650C;
    }
    
    @media (min-width: 600px) {
      .not-found-container h2 {
        font-size: 52px;
      }
    }
  `

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: '404' })
    }
  }, [])

  return (
    <>
      <PageHelmet title="Page not found" />

      <div className="text-center not-found-container">
        <img src={notFound} alt="searching guy" className="not-found-image" />
        <style dangerouslySetInnerHTML={{ __html: styles }} />
        <h1>Page not found</h1>
        <h2>Oh no! We couldn&apos;t find what you were looking for!</h2>

        <div className="helping-links">
          <a
            href="https://www.wikijob.co.uk/contact-us"
            className="orange-link"
          >
            Tell us something is wrong
          </a>
          <a
            href="https://www.wikijob.co.uk/search?q=#gsc.tab=0"
            className="orange-link"
          >
            Search our website
          </a>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
